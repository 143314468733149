import { phoneFormat } from '../../../util/formatters'
import { SUPPORT_NUMBER } from '../../../util/support'

const NoProvidersAvailable = () => {
  const supportNumber = (
    <a href={`tel:+${SUPPORT_NUMBER}`} className="text-link font-bold">
      {phoneFormat(SUPPORT_NUMBER)}
    </a>
  )
  return (
    <div className="flex flex-grow flex-col items-center text-center">
      <h3 className="typography-h4 text-neutral py-8 font-normal">
        We need more information to connect you with the right provider
      </h3>
      <p className="typography-body-l py-6">
        Please contact {supportNumber} and a support team member will help you 24/7
      </p>
    </div>
  )
}

export default NoProvidersAvailable
