import { ComponentPropsWithoutRef, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import ErrorIcon from '@ui-components-3/icons/lib/solid/TriangleExclamation'
import Modal, { ModalChild, ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import ConfirmCloseDialog from 'components/common/ConfirmCloseDialog'
import { AccountMergeScene } from 'constants/accountMerge'
import useAccountMerge, { AccountMergeContext, useAccountMergeFlow } from 'hooks/useAccountMerge'
import { HELP_CENTER } from 'routes/paths'
import { toast } from 'util/toast'

import { EntryContent, EntryFooter } from './scenes/Entry'
import { SelectAccountContent, SelectAccountFooter } from './scenes/SelectAccount'
import { SelectMfaContent, SelectMfaFooter } from './scenes/SelectMfa'
import { TermsAndPolicyContent, TermsAndPolicyFooter } from './scenes/TermsAndPolicy'
import { VerifyIdentityContent, VerifyIdentityFooter } from './scenes/VerifyIdentity'
import { VerifyMfaContent, VerifyMfaFooter } from './scenes/VerifyMfa'
import { VerifyPasswordContent, VerifyPasswordFooter } from './scenes/VerifyPassword'

export const toastMergeFailed = () =>
  toast.error(
    <p className="m-xxs text-neutral leading-[18px]">
      Verification process failed. Please visit our{' '}
      <Link className="text-link font-medium" to={HELP_CENTER}>
        Help Center
      </Link>{' '}
      for next steps.
    </p>,
  )

const Error = ({ children, ...props }: ComponentPropsWithoutRef<'p'>) => (
  <p
    className="typography-body-l gap-xxs pt-s inline-flex items-center font-medium text-fuchsia-800"
    role="alert"
    {...props}
  >
    <ErrorIcon aria-hidden="true" className="m-xs h-auto w-5 shrink-0" />
    {children}
  </p>
)

const LABELS_BY_SCENE = {
  entry: (multiple) => `Account duplicate${multiple ? 's' : ''} found`,
  terms: 'Terms and policy',
  selectAccount: 'Select email',
  verifyPassword: 'Verify your password',
  verifyIdentity: 'Verify your identity',
  selectMfa: 'Verification method',
  verifyMfa: 'Enter verification code',
} as const satisfies Record<AccountMergeScene, string | ((x: boolean) => string)>

const CONTENT_BY_SCENE = {
  entry: EntryContent,
  terms: TermsAndPolicyContent,
  selectAccount: SelectAccountContent,
  verifyPassword: VerifyPasswordContent,
  verifyIdentity: VerifyIdentityContent,
  selectMfa: SelectMfaContent,
  verifyMfa: VerifyMfaContent,
} as const satisfies Record<AccountMergeScene, ModalChild>

const FOOTER_BY_SCENE = {
  entry: EntryFooter,
  terms: TermsAndPolicyFooter,
  selectAccount: SelectAccountFooter,
  verifyPassword: VerifyPasswordFooter,
  verifyIdentity: VerifyIdentityFooter,
  selectMfa: SelectMfaFooter,
  verifyMfa: VerifyMfaFooter,
} as const satisfies Record<AccountMergeScene, ModalChild>

const AccountMerge = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const confirmationModalRef = useRef<ModalRef>(null)
  const params = useParams<{ messageId?: string }>()

  const flow = useAccountMergeFlow({ taskId: params?.messageId })
  const { error, ...context } = flow
  const scene = flow.scene

  const Content = CONTENT_BY_SCENE[scene]
  const Footer = FOOTER_BY_SCENE[scene]
  const label = LABELS_BY_SCENE[scene]
  const finalLabel =
    typeof label === 'string' ? label : label(params?.messageId ? false : flow.duplicateAccounts?.length > 1)

  const cancelMerge = () => {
    flow.restart()
    onClose()
  }
  const { handleSaveForLater } = useAccountMerge()

  const handleCancel = async (ignoreConfirmation = false) => {
    // retyping NON_CHALLENGE_SCENES bc ts complains about scene (AccountMergeScene) including values that don't exist in NON_CHALLENGE_SCENES
    const closeWithoutConfirm = ['entry', 'terms', 'selectAccount'].includes(scene)
    if (ignoreConfirmation) {
      return cancelMerge()
    }
    if (closeWithoutConfirm) {
      if (!flow.task) {
        await handleSaveForLater()
      }
      cancelMerge()
    } else {
      confirmationModalRef.current?.open()
    }
  }

  return (
    <AccountMergeContext.Provider value={{ ...context, handleCancel }}>
      <Modal
        opened={open}
        size="custom"
        footerVariant="right"
        label={finalLabel}
        className="!text-neutral w-[514px] backdrop:backdrop-brightness-50 sm:max-w-[90vw]"
        onCustomClose={() => handleCancel()}
        footer={(renderProps) => <Footer {...renderProps} />}
      >
        {(renderProps) => (
          <>
            <Content {...renderProps} />
            {error ? <Error>{error}</Error> : null}
          </>
        )}
      </Modal>
      <ConfirmCloseDialog
        ref={confirmationModalRef}
        cancelClassName="!btn-neutral-outlined"
        confirmClassName="!btn-fuchsia-outlined"
        confirmLabel="Cancel Merge"
        cancelLabel="No"
        label="Cancel Merge?"
        onConfirm={cancelMerge}
      >
        Are you sure you’d like to cancel this process?
      </ConfirmCloseDialog>
    </AccountMergeContext.Provider>
  )
}

export default AccountMerge
