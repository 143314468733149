import { StepFormBaseProps } from '../types'
import PaymentForm from 'forms/visit-creation/PaymentForm'
import NewVisitPaymentSchema from 'schemas/NewVisitPaymentSchema'

type SuccessPaymentProps = StepFormBaseProps & {
  backAction?: () => void
  onCancel?: () => void
  isOverLimit?: boolean
}

const SuccessPayment = ({ initialValues, onSubmit, backAction, ...resOfProps }: SuccessPaymentProps) => {
  return (
    <div className="p-8">
      <PaymentForm
        {...resOfProps}
        onBack={backAction}
        onSubmit={onSubmit}
        schema={NewVisitPaymentSchema}
        initialValues={initialValues}
        isRequiredLabelVisible={false}
      />
    </div>
  )
}

export default SuccessPayment
