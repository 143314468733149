import { useMemo } from 'react'
import ExternalLink from 'components/common/ExternalLink'
import { Markdown, DEFAULT_MARKDOWN_RENDERERS, MarkdownRenderers } from 'react-marked-renderer'

const isExternalLink = (href?: string) => {
  return href && typeof href === 'string' && !href.match(/timelycare\.com/gi)
}

const sanitizeMarkdown = (content?: string) => {
  if (!content) return ''

  return (
    content
      // Replace each <br> tag with a newline, preserving multiple <br>s
      .replace(/<br\s*\/?>/g, '\n')
      // Replace &nbsp; with actual non-breaking space
      .replace(/&nbsp;/g, '\u00A0')
      // Remove spaces at the beginning of lines (to prevent code block interpretation)
      .replace(/^[ \t]+/gm, '')
  )
}

const renderers: MarkdownRenderers = {
  ...DEFAULT_MARKDOWN_RENDERERS,
  link: (props) => <ExternalLink {...props} showAlert={isExternalLink(props.href)} />,
  list: (props) => {
    const { ordered, start } = props

    if (ordered && start && start > 1) {
      return <ol start={start}>{props.children}</ol>
    }

    return <DEFAULT_MARKDOWN_RENDERERS.list {...props} />
  },
}

type MarkdownViewProps = {
  content?: string
}

const MarkdownView = ({ content }: MarkdownViewProps) => {
  const parsedContent = useMemo(() => sanitizeMarkdown(content), [content])

  if (!parsedContent) {
    return null
  }

  return (
    <div className="prose prose-neutral">
      <Markdown markdown={parsedContent} options={{ breaks: true, gfm: true }} renderers={renderers} />
    </div>
  )
}

export default MarkdownView
