import CryptoJS from 'crypto-js'

export const encrypt = (text, key) => {
  return CryptoJS.AES.encrypt(text, key).toString()
}

export const decrypt = (cipher, key) => {
  const bytes = CryptoJS.AES.decrypt(cipher, key)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const joinKeys = (providerKey, memberKey) => {
  return providerKey + memberKey
}

export const joinParticipantKeys = (keys = []) => {
  return keys.join('')
}
