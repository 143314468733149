import { RootState } from 'redux/store'
import { createSelector } from 'reselect'

export const getAuth = (state: RootState) => state.auth

export const getToken = createSelector(getAuth, (store) => store.auth && store.auth.token)
export const getUserId = createSelector(getAuth, (store) => store.auth && store.auth.userId)
export const getCreatedAt = createSelector(getAuth, (store) => store.auth && store.auth.createdAt)
export const getExpiresAt = createSelector(getAuth, (store) => store.auth && store.auth.expiresAt)
export const getRefreshToken = createSelector(getAuth, (store) => store.auth && store.auth.refreshToken)

export const getSessionTokens = createSelector(getAuth, (store) => store.sessionTokens)

export const getAccountMergeSessionToken = createSelector(
  getSessionTokens,
  (sessionTokens) => sessionTokens.accountMergeToken,
)
