import { createSlice } from '@reduxjs/toolkit'

export type AuthState = {
  auth: {
    token: string
    refreshToken: string
    userId: string
    createdAt: string
    expiresAt: string
  }
  sessionTokens: {
    accountMergeToken: string
  }
}

const defaultState: AuthState = {
  auth: {
    token: '',
    refreshToken: '',
    userId: '',
    createdAt: '',
    expiresAt: '',
  },
  sessionTokens: {
    accountMergeToken: '',
  },
}

const slice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    putAuth: (state, action) => {
      const { auth } = action.payload
      state.auth.token = auth.token
      state.auth.refreshToken = auth.refreshToken
      state.auth.userId = auth.userId
      state.auth.createdAt = auth.createdAt
      state.auth.expiresAt = auth.expiresAt
    },
    putToken: (state, action) => {
      const { token } = action.payload
      state.auth.token = token
    },
    clearAuth: (state) => {
      state.auth = { ...defaultState.auth }
    },
    putAccountMergeToken: (state, action) => {
      const { token } = action.payload
      state.sessionTokens.accountMergeToken = token
    },
    clearAccountMergeToken: (state) => {
      state.sessionTokens.accountMergeToken = ''
    },
  },
})

const { actions, reducer } = slice
export const { putAuth, putToken, clearAuth, putAccountMergeToken, clearAccountMergeToken } = actions
export default reducer
