import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import * as SchedulingSelectors from '../../../redux/selectors/scheduling'
import withForm from '../../../hoc/withForm'
import SchedulingField from '../../../components/visit-creation/SchedulingField'
import TestIds from '../../../util/TestIds'
import { reduceAvailabilityToTimesByDate } from '../../../util/scheduling'
import { getProvider } from '../../../redux/selectors/providers'
import moment from 'moment-timezone'
import { phoneFormat } from '../../../util/formatters'
import { SUPPORT_NUMBER } from '../../../util/support'
import Member from 'types/member/member'
import PendingVisit from 'types/visit/pendingVisit'
import { AnyObjectSchema } from 'yup'
import { FormikErrors } from 'formik'
import type { Dispatch } from 'redux'

type FormValue = {
  timezone: string
  scheduledAt: string | null
}

type SchedulingFormProps = {
  member: Member
  pendingVisit: PendingVisit
  initialValues: FormValue
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  setErrors: (fields: { [field: string]: string }) => void
  values: FormValue
  errors: FormikErrors<FormValue>
  touched: { [field: string]: boolean }
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: (value: { disabled: boolean }) => JSX.Element
  fetchProviderAvailability: () => Promise<
    | ((dispatch: Dispatch) => Promise<{
        success: boolean
        data: null
      }>)
    | null
  >
}

const SchedulingForm = (props: SchedulingFormProps) => {
  const { pendingVisit, setFieldValue, handleSubmit, errors, touched, isValid, fetchProviderAvailability, isLoading } =
    props

  const availability = useSelector(SchedulingSelectors.getProviderAvailability(pendingVisit.providerId))

  const timezone = useMemo(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }, [])

  const availabilityMap = useMemo(() => {
    if (isEmpty(availability)) return {}
    return reduceAvailabilityToTimesByDate(availability, timezone)
  }, [availability, timezone])

  useEffect(() => {
    fetchProviderAvailability()
  }, [pendingVisit.providerId])

  useEffect(() => {
    setFieldValue('timezone', timezone)
  }, [timezone])

  const isNextDisabled = !isValid

  const provider = useSelector(getProvider(pendingVisit?.providerId))

  useEffect(() => {
    if (pendingVisit?.scheduledAt && availability?.length && !isLoading) {
      const hasScheduledAvailability = availability.some((time: string) => {
        const availabilityTime = moment(time).tz(timezone)
        const scheduledTime = moment(pendingVisit.scheduledAt).tz(timezone)
        return availabilityTime.isSame(scheduledTime, 'minute')
      })

      if (!hasScheduledAvailability) {
        setFieldValue('scheduledAt', null)
      }
    }
  }, [availability, pendingVisit?.scheduledAt, isLoading])

  if (!provider || !availability || availability.length === 0) {
    return null
  }

  const hasError = errors && touched.scheduledAt && errors.scheduledAt

  return (
    <form className="relative flex w-full flex-1 flex-col" onSubmit={handleSubmit}>
      <div className="pb-6">
        <div className="typography-h5 mb-4 text-neutral-800">Select a date</div>
        <SchedulingField
          error={hasError ? hasError : ''}
          data-testid={TestIds.newVisit.input.scheduledAt}
          timesByDate={availabilityMap}
          timezone={timezone}
          dateLabel={'Date'}
          timeLabel={'Time'}
          name={'scheduledAt'}
        />
        <div className="typography-body mt-6 text-neutral-800">
          Need help finding an appointment? Call{' '}
          <a className="text-link font-semibold" href={`tel:+${SUPPORT_NUMBER}`}>
            {phoneFormat(SUPPORT_NUMBER)}
          </a>{' '}
          to connect with our care coordinators.
        </div>
      </div>
      {props.children({ disabled: isNextDisabled })}
    </form>
  )
}

export default withForm(SchedulingForm)
