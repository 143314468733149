import withForm from 'hoc/withForm'
import TestIds from 'util/TestIds'
import { AnyObjectSchema } from 'yup'
import { useGeolocated } from 'react-geolocated'
import PendingVisit from 'types/visit/pendingVisit'
import ManStandingPhoneLink from 'assets/images/man_standing_phone.svg'
import LocationIcon from '@ui-components-3/icons/lib/regular/LocationDot'
import FormikSelect from '@ui-components-3/compat/lib/components/FormikSelect'
import { MinOption } from 'types/form/Form'
import States from 'util/states'
import {
  VisitCreationForm,
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import { useRef } from 'react'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { useCallback } from 'react'
import { normalizeJsonFromApi } from 'api/helpers'
import dirtyReverseGeocode from 'util/dirtyReverseGeocode'
import { useHistory } from 'react-router'
import { ADD_PENDING_VISIT, createWithQuery } from 'routes/paths'
import { Steps } from 'pages/AddPendingVisitPage/AddPendingVisitPage'
import ActionDivider from 'components/visit-creation/ActionDivider'

const getOptionLabel = (o: MinOption) => o.label
const getOptionValue = (o: MinOption) => o.value

type initialValues = {
  location: string
  locationCountry: string
  memberDob: string
  memberFirstName: string
  memberId: string
  memberLastName: string
  price: number
  reasonForVisit: string
  scheduledType: string
  timezone: string
  visitType: string
}

type NewVisitStateFormProps = {
  initialValues: initialValues
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  values: initialValues
  errors: object
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: (values: { disabled: boolean }) => JSX.Element
  positionError: any
  clearPayload: () => void
  pendingScheduledVisits: { [key: string]: string[] }
  userMemberId: string
  pendingVisit: PendingVisit
  redirectToCountry: () => void
}

export const NewVisitStateForm = (props: NewVisitStateFormProps) => {
  const { handleSubmit, isValid, schema, setFieldValue } = props
  const history = useHistory()

  const modalRef = useRef<ModalRef>(null)
  const { isGeolocationEnabled, isGeolocationAvailable, coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  })

  const handleLocateClick = useCallback(async () => {
    if (!coords) return null
    const location: { stateAbbr?: string } = normalizeJsonFromApi(
      await dirtyReverseGeocode(coords.latitude, coords.longitude, 'us'),
    )
    if (!location || !location.stateAbbr || !States.find((c) => c.value === location.stateAbbr)) {
      history.push(
        createWithQuery(ADD_PENDING_VISIT, {
          step: Steps.locationCountry,
        }),
      )
    }
    setFieldValue('location', location.stateAbbr)
  }, [coords, history, setFieldValue])

  const disabled = !coords || !isGeolocationAvailable || !isGeolocationEnabled

  return (
    <VisitCreationScene>
      <VisitCreationForm>
        <form onSubmit={handleSubmit} data-testid={TestIds.newVisit.view.locationStateForm} className="flex flex-col">
          <div className="relative z-10">
            <p className="typography-body mb-10 mt-2 font-medium text-neutral-700">
              All fields marked with asterisks are required*
            </p>
            <h2 className="typography-h5 mb-8 text-neutral-800">Which state are you currently in?*</h2>
            <FormikSelect
              required={true}
              schema={schema}
              name={'location'}
              label={'State'}
              options={States}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              portal
            />
            <ActionDivider className="my-8">
              <p className="typography-body font-semibold text-neutral-400">OR</p>
            </ActionDivider>
            <div className="flex justify-center">
              <button
                disabled={disabled}
                className={classNames(
                  'text-primary-600 hover:text-secondary-700 typography-body-l flex items-center gap-1 text-center font-medium',
                  disabled ? 'text-neutral-400' : 'text-primary-600',
                )}
                onClick={handleLocateClick}
                data-testid={TestIds.newVisit.button.locate}
                type="button"
              >
                <div className="flex h-8 w-8 items-center justify-center px-2 py-[5px]">
                  <LocationIcon className="h-full w-full" aria-hidden="true" />
                </div>
                Find My Location
              </button>
            </div>
            <button
              type="button"
              className="text-link typography-body mt-8 self-center"
              onClick={() => modalRef.current?.open()}
            >
              Why are we asking this?
            </button>
          </div>
          <Modal
            ref={modalRef}
            label="Why are we asking this?"
            size={'m'}
            contentVariant={'default'}
            footerVariant={'default'}
            footer={({ close }) => (
              <button type="button" className="btn btn-neutral" onClick={close}>
                Got it
              </button>
            )}
          >
            <p className="typography-body font-medium text-neutral-800">
              Your physical location, not your permanent address, may determine the type of services and providers
              available to you.
            </p>
          </Modal>
          {props.children({ disabled: !isValid })}
        </form>
      </VisitCreationForm>
      <VisitCreationImage>
        <img className="h-auto w-full object-contain" src={ManStandingPhoneLink} alt="" />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default withForm(NewVisitStateForm)
