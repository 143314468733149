import useAccountMerge, { useAccountMergeContext } from 'hooks/useAccountMerge'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import { ModalRenderPropParams } from '@ui-components-3/ui/lib/components/Modal'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import { Link } from 'react-router-dom'
import { HELP_CENTER } from 'routes/paths'
import RequiredFieldLabel from 'components/form/RequiredFieldLabel'
import useId from '@ui-components-3/ui/lib/hooks/useId'

export const SelectAccountFooter = (_: ModalRenderPropParams) => {
  const { selectedMemberId, handleNextScene } = useAccountMergeContext()
  const { handleSaveForLater, isSavingForLater } = useAccountMerge()
  const handleSubmit = () => {
    handleNextScene('terms')
  }
  return (
    <>
      <button
        type="button"
        onClick={handleSaveForLater}
        disabled={isSavingForLater}
        className="btn btn-neutral-outlined self-end"
      >
        Save for Later
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        disabled={!selectedMemberId || isSavingForLater}
        className="btn btn-neutral self-end"
      >
        Next
      </button>
    </>
  )
}

export const SelectAccountContent = (_: ModalRenderPropParams) => {
  const { selectDuplicate, selectedMemberId, duplicateAccounts } = useAccountMergeContext() || {}
  const radioGroupLabelId = useId()

  return (
    <div className="gap-s pt-xs flex flex-col">
      <RequiredFieldLabel ignoreFieldsLength className="font-normal" />
      <p className="typography-body-l font-medium" id={radioGroupLabelId}>
        Select which email you would like to merge with your login account.*
      </p>
      <RadioCardsGroup
        aria-labelledby={radioGroupLabelId}
        options={duplicateAccounts}
        columns={1}
        value={selectedMemberId}
        onChange={(e) => selectDuplicate(e.target.value)}
        getOptionLabel={(o) => o?.member?.email}
        getOptionValue={(o) => o.duplicateMemberId}
      />
      <Notice variant="warning">
        <div className="typography-body">
          <p className="font-semibold">Account verification notice</p>
          <p>
            If this account does not belong to you, please call us at (833) 484-6359 or visit our{' '}
            <Link className="text-link font-medium" to={HELP_CENTER}>
              Help Center.
            </Link>
          </p>
        </div>
      </Notice>
    </div>
  )
}
