import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import Notice from '@ui-components-3/ui/lib/components/Notice'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { HELP_CENTER } from 'routes/paths'
import Loading from '@ui-components-3/ui/lib/components/Loading'

type VisitLimitDialogProps = {
  serviceType: string
  isLoading: boolean
}

const VisitLimitDialog = forwardRef<ModalRef, VisitLimitDialogProps>(
  ({ serviceType, isLoading }: VisitLimitDialogProps, ref) => {
    return (
      <Modal label={`${serviceType} visit limit`} size="m" ref={ref}>
        {isLoading ? (
          <Loading variant="big" />
        ) : (
          <div className="flex flex-col gap-4">
            <p className="typography-body-l text-neutral-800">It seems you have reached your visit limit.</p>
            <Notice variant="info">
              <p>
                If you need assistance, please reach out to our support team in the{' '}
                <Link
                  to={HELP_CENTER}
                  className="text-link font-semibold"
                  aria-label="Click here to navigate to the help center"
                >
                  Help Center
                </Link>
                .
              </p>
            </Notice>
          </div>
        )}
      </Modal>
    )
  },
)

export default VisitLimitDialog
