import * as Yup from 'yup'
import { union } from 'lodash'
import { FACULTY_STAFF_CATEGORIES, STUDENT_CATEGORIES } from 'types/client/group'

export const AddPostFormSchema = Yup.object({
  category: Yup.string()
    .oneOf(union(STUDENT_CATEGORIES, FACULTY_STAFF_CATEGORIES))
    .required('You must select a category to create a post'),
  content: Yup.string()
    .label('Create Post')
    .min(1, 'Create Post must be at least 1 character long')
    .max(500, 'Create Post cannot exceed 500 characters')
    .required(),
})

export const AddCommentFormSchema = Yup.object().shape({
  content: Yup.string()
    .min(1, 'Your comment must be at least 1 character long')
    .max(500, 'Your comment cannot exceed 500 characters')
    .required('Comment is a required field'),
})

export const AddPostRequestSchema = Yup.object().shape({
  post: Yup.object().shape({
    authorId: Yup.string().required(),
    authorAvatarColors: Yup.object()
      .shape({
        alias: Yup.string().required(),
        background: Yup.string().required(),
        border: Yup.string().required(),
      })
      .required(),
    authorUsername: Yup.string().required(),
    authorName: Yup.string().required(),
    authorType: Yup.string().required(),
    category: Yup.string().oneOf(union(STUDENT_CATEGORIES, FACULTY_STAFF_CATEGORIES)).required(),
    content: Yup.string().required(),
    postType: Yup.string().required(),
  }),
})

export const AddCommentRequestSchema = Yup.object().shape({
  comment: Yup.object().shape({
    authorId: Yup.string().required(),
    authorAvatarColors: Yup.object()
      .shape({
        alias: Yup.string().required(),
        background: Yup.string().required(),
        border: Yup.string().required(),
      })
      .required(),
    authorUsername: Yup.string().required(),
    authorName: Yup.string().required(),
    authorType: Yup.string().required(),
    content: Yup.string().required(),
  }),
})
