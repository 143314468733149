import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import capitalize from 'lodash/capitalize'
import { phoneFormat } from './helpers'
import Scenes from './Scenes'
import { addMedicalHistory, updateMedicalHistory } from '../redux/thunks/medical-history'

import { removeNullObjects } from './helpers'
import { mapConditionsToPrimitiveValues } from './conditions'
import { createSimpleSelectOption } from './form'
import MedicalHistory, { Medication, MedicationV2 } from 'types/member/medicalHistory'
import { ReactNode } from 'react'
import { parseMedicationDate } from './medication'

const calculateRange = (lowerLimit, upperLimit, labelFormatter) => {
  const label = labelFormatter ? labelFormatter : (value) => `${value}`

  const result = []
  for (let i = lowerLimit; i < upperLimit; i++) {
    result.push(createSimpleSelectOption(i, label))
  }
  return result
}

export const calculateInchOptions = () =>
  calculateRange(0, 12, (value) => (value === 1 ? `${value} Inch` : `${value} Inches`))
export const calculateFeetOptions = () => calculateRange(0, 9, (value) => `${value} Feet`)

export const normalizeMedicalHistory = (medHistory) => {
  const medicalHistory = clone(medHistory)
  if (medicalHistory) {
    delete medicalHistory.medicalHistoryId
    delete medicalHistory.medicalHistoryVersion
    delete medicalHistory.updatedAt
    if (medicalHistory && !isEmpty(medicalHistory.lifestyle)) {
      // Clean up lifestyle
      if (!medicalHistory.lifestyle.drink) {
        delete medicalHistory.lifestyle.drinkFrequency
      }
    }
  }

  return removeNullObjects(medicalHistory)
}

export const calculateMedicalHistoryStatus = (history: Partial<MedicalHistory> = {}) => ({
  completedGeneral: !isNil(history.heightFt) && !isNil(history.weightLbs),
  completedLifestyle:
    history.lifestyle &&
    history.lifestyle &&
    !isNil(history.lifestyle.smoke) &&
    !isNil(history.lifestyle.drink) &&
    !isNil(history.lifestyle.sexuallyActive),
  completedMedicalHistory: history.conditionsCompleted,
  completedFamilyHistory: history.familyHistoryCompleted,
  completedMedications: history.medicationsCompleted,
  completedAllergies: history.allergiesCompleted,
  completedPrimaryCare: history.primaryCareCompleted,
})

export const normalizeFamilyHistory = (conditions) => {
  const relationships = groupBy(conditions, 'relationship')
  return keys(relationships).map((relationship) => {
    const conditionList = relationships[relationship]
    const conditionsName = reduce(
      conditionList,
      (cond, item) => {
        return `${item.name}, ${cond}`
      },
      '',
    )
    return {
      title: relationship,
      content: conditionsName.replace(/,\s*$/, ''),
      conditions: mapConditionsToPrimitiveValues(conditions.filter((c) => c.relationship === relationship)),
    }
  })
}

export const getMedicationV2Content = (medication: MedicationV2) => {
  if (!medication) {
    return ''
  }

  const { strengthAndUnit, unit, direction, startDate, endDate } = medication

  return (
    <div className="typography-body flex flex-col gap-1 font-medium">
      <p>
        <span className="font-semibold">Strength/Unit:</span> {[strengthAndUnit, unit].filter(Boolean).join(' ')}
      </p>
      {(!!startDate || !!endDate) && (
        <p>
          <span className="font-semibold">Date:</span>{' '}
          {[startDate, endDate].map((date) => parseMedicationDate(date) || 'Current').join(' - ')}
        </p>
      )}
      {!!direction && (
        <p>
          <span className="font-semibold">Direction:</span> {direction}
        </p>
      )}
    </div>
  )
}

export const normalizeMedications = (
  medications: Array<Medication | MedicationV2>,
): Array<{ title: string; content: ReactNode; medication: Medication | MedicationV2; subtitle: string }> => {
  return (medications || []).map((med) => {
    const isV2 = 'status' in med

    if (!isV2) {
      return {
        title: med.name,
        content: `${med.dosage}-${med.unit}, ${med.frequency}`,
        medication: med,
        subtitle: med.active ? 'Active Reception' : '',
      }
    }

    const { name, status } = med

    return {
      title: name,
      content: getMedicationV2Content(med),
      medication: med,
      subtitle: status === 'Current' ? 'Active Reception' : '',
    }
  })
}

export const normalizeAllergies = (allergies) => {
  return (
    allergies &&
    allergies.map((allergy) => ({
      title: allergy.allergyDescription,
      subtitle: capitalize(allergy.allergyType),
      content: allergy.reaction,
      allergy,
    }))
  )
}

export const normalizeProviders = (providers) => {
  return (
    !isEmpty(providers) &&
    providers.map((provider) => ({
      title: provider.name,
      subtitle: capitalize(provider.providerType),
      content: `${phoneFormat(provider.phones.find((phone) => phone.type === 'office').number)} - Office`,
      provider,
    }))
  )
}

export const basicInfoCompleted = (medicalHistory) => {
  if (medicalHistory.heightIn > -1 && medicalHistory.heightFt > -1) {
    return true
  }
  return false
}

export const saveMedicalHistory = async (medicalHistory, values, memberId, dispatch, onNext?: () => void) => {
  const { success, data } = isEmpty(medicalHistory)
    ? await dispatch(
        addMedicalHistory(memberId, {
          ...medicalHistory,
          ...values,
          memberId,
        }),
      )
    : await dispatch(
        updateMedicalHistory(memberId, {
          ...normalizeMedicalHistory(medicalHistory),
          ...values,
          memberId,
        }),
      )
  if (success && data && onNext) {
    onNext()
  }
}

export const getItemDetails = (item) => {
  if (item.medication) {
    return {
      id: item.medication.id,
      title: 'Remove Medication',
      description: 'Are you sure you would like to remove this medication?',
    }
  } else if (item.allergy) {
    return {
      id: item.allergy.allergyId,
      title: 'Remove Allergy',
      description: 'Are you sure you would like to remove this allergy?',
    }
  } else if (item.provider) {
    return {
      id: item.provider.providerId || item.provider.id,
      title: 'Remove Provider',
      description: 'Are you sure you would like to remove this provider?',
    }
  } else {
    return {
      id: item.title,
      title: 'Remove Family Member',
      description: 'Are you sure you would like to remove this family member?',
    }
  }
}

// This is for the pre-visit flow to determine which types of visits require medical info
export const isMedicalWizardNeeded = (values, scene, medicalHistoryCompleted) => {
  // These visit types do not require completion of medical info to create a visit
  const INFO_EXEMPT_VISIT_TYPES = ['hc', 'success_coaching', 'success_coaching_now']
  // Talk Now visit types are also exempt
  const isTalkNowVisit = values.visitType === 'therapy' && values.scheduledType === 'now'
  const isExemptVisitType = INFO_EXEMPT_VISIT_TYPES.includes(values.visitType)

  // The medical wizard should follow the visit type screen if medical inofrmation has not been completed
  const isMedicalWizardNextStep = scene === Scenes.newVisit.visitType && !medicalHistoryCompleted

  return isMedicalWizardNextStep && !isExemptVisitType && !isTalkNowVisit
}
