import { FC, ReactNode } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

/*
  HOC to create a "Toggle" component for feature flags.
  Do NOT use on components mounted before AuthRoute - will never resolve as isAttributesReady

  Each toggle can be short or long lived.
  In short lived toggles, we'd delete this toggle file and
  refactor to wire in whichever path (on or off) we've decided to make permanent
 */

const withFeatureFlagToggle =
  <TProps extends Record<string, any>>(flagName: string) =>
  (OnChild: FC<TProps> | any, OffChild: FC<TProps> | any, pendingNode: ReactNode = null) =>
  (props: TProps) => {
    const flags = useFlags()

    const isFlaggedOn = flags?.[flagName]
    const isReady = typeof isFlaggedOn === 'boolean'

    if (!isReady) {
      return pendingNode
    }

    return isFlaggedOn ? <OnChild {...props} /> : <OffChild {...props} />
  }

export default withFeatureFlagToggle
