import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'
import { MedicationSchema } from '../../../schemas/MedicalHistorySchema'
import { normalizeJsonToApi } from '../../helpers'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  medication: MedicationSchema.required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const medication = MedicationSchema.cast(params.medication, { stripUnknown: true })
  return normalizeJsonToApi({ medications: [medication] })
}
export const url = ({ memberId }) => `/v1/${memberId}/personal/history/medications`
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
