import { useMemo, useEffect } from 'react'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { getFromStorage } from '../util/storage'
import * as MemberSelectors from '../redux/selectors/members'
import * as SessionKeys from '../util/session-constants'
import * as MemberThunks from '../redux/thunks/members'
import { useRouteMatch } from 'react-router'
import SubscriptionStatus from '../util/subscription-status'
import queryString from 'query-string'
import FetchTypes from 'util/fetch-types'
import { isTypeFetching } from 'redux/selectors/api'
import { CLINICAL_ONLY_PATHS } from 'routes/paths'
import { useQuery } from 'hooks/useQuery'
import { setCampaign } from 'redux/slices/api'

const BLOCKED_SUBSCRIPTION_STATUSES = [
  SubscriptionStatus.SUBSCRIPTION_OFFERED_UNAFFILIATED,
  SubscriptionStatus.CANCELLED,
  SubscriptionStatus.OPT_OUT_UNAFFILIATED,
]

export default (props) => {
  const dispatch = useDispatch()
  const { location, path, isPrivate } = props
  const match = useRouteMatch(path)
  const storedMemberId = useMemo(() => getFromStorage(SessionKeys.MEMBER_ID), [])
  const reduxMemberId = useSelector(MemberSelectors.getLoggedInMemberId)
  const member = useSelector(MemberSelectors.getMember(storedMemberId || reduxMemberId))
  const memberAge = useSelector(MemberSelectors.getMemberAge(storedMemberId))
  const hasConsentFiled = useSelector(MemberSelectors.getHasConsentFiled(storedMemberId))
  const termsAcceptedDate = useSelector(MemberSelectors.getTcAppAgreementDate)
  const isFetchingTerms = useSelector(isTypeFetching(FetchTypes.updateTermsConditions))
  const isDigitalOnly = useSelector(MemberSelectors.getDigitalOnlyStatus)
  const INITIAL_ANNUAL_TERMS_DATE = '2024-01-31T00:00:00.000+00:00'
  const { campaign } = useQuery(['campaign'])

  useEffect(() => {
    if (campaign) {
      dispatch(setCampaign(campaign))
    }
  }, [campaign, dispatch])

  const isTermsAgreementExpired = useMemo(() => {
    const currentDate = new Date()
    const initialAnnualTermsDateObject = new Date(INITIAL_ANNUAL_TERMS_DATE)

    // The annual terms & conditions will not begin until Jan 31 2024
    if (currentDate < initialAnnualTermsDateObject) {
      return false
    }

    // Redirect if termsAcceptedDate does not exist
    if (!termsAcceptedDate) return true

    const currentYear = currentDate.getFullYear()
    const acceptanceDate = new Date(termsAcceptedDate)

    // If we are in the month of january, previousTermsAgreementDeadline will be from last year, else it is this year
    const previousTermsAgreementDate = new Date(currentDate.getMonth() === 0 ? currentYear - 1 : currentYear, 0, 31)

    return acceptanceDate <= previousTermsAgreementDate
  }, [termsAcceptedDate])

  const queryParams = useMemo(() => {
    if (!location?.search) return {}
    return queryString.parse(location.search)
  }, [location?.search])

  const accessToken = useMemo(() => {
    return getFromStorage(SessionKeys.TOKEN)
  }, [])

  const timezone = useMemo(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }, [])

  const isTokenExpired = useMemo(() => {
    if (!accessToken) return true
    const expirationDate = getFromStorage(SessionKeys.TOKEN_EXPIRES_AT)
    const now = moment().tz(timezone)
    try {
      const exp = moment(expirationDate).tz(timezone)
      return now.isSameOrAfter(exp)
    } catch (e) {
      console.error(e)
      return true
    }
  }, [accessToken, timezone])

  const isTokenRefreshable = useMemo(() => {
    const createdDate = getFromStorage(SessionKeys.TOKEN_CREATED_AT)
    const now = moment().tz(timezone)
    try {
      const refreshExpiration = moment(createdDate).tz(timezone).add(16, 'hours')
      return now.isBefore(refreshExpiration)
    } catch (e) {
      console.error(e)
      return true
    }
  }, [timezone])

  const isRelevantPath = useMemo(() => {
    if (!match) return false
    return path === match.path
  }, [path, match])

  const shouldRedirectToLogin = useMemo(() => {
    if (!isRelevantPath) return false
    return isPrivate && (!accessToken || (isTokenExpired && !isTokenRefreshable))
  }, [isPrivate, isRelevantPath, accessToken, isTokenExpired, isTokenRefreshable])

  const shouldRedirectToTerms = useMemo(() => {
    if (!member || !isPrivate || isRelevantPath || isFetchingTerms) return false
    return isTermsAgreementExpired
  }, [isFetchingTerms, isPrivate, isRelevantPath, isTermsAgreementExpired, member])

  const shouldRedirectToUnderage = useMemo(() => {
    if (!member || !isRelevantPath || !isPrivate) return false
    return memberAge < 18 && !hasConsentFiled
  }, [member, memberAge, isPrivate, isRelevantPath, hasConsentFiled])

  const shouldRedirectToGraduation = useMemo(() => {
    if (!isPrivate || !member) return false
    return (
      (member.migratedMember && !member.mobileSubscriptionId) ||
      BLOCKED_SUBSCRIPTION_STATUSES.includes(member.subscriptionStatus)
    )
  }, [member, isPrivate])

  const shouldRedirectToSSO = useMemo(() => {
    return (
      isPrivate &&
      isRelevantPath &&
      Boolean(queryParams?.access_token) &&
      Boolean(queryParams?.refresh_token) &&
      Boolean(queryParams.member_id)
    )
  }, [queryParams, isPrivate, isRelevantPath])

  const shouldRedirectDigitalOnlyMember = useMemo(() => {
    if (!member || !isRelevantPath || !isPrivate) return false
    if (isDigitalOnly) {
      return CLINICAL_ONLY_PATHS.some((_path) => path.startsWith(_path))
    }
  }, [isDigitalOnly, isPrivate, isRelevantPath, member, path])

  useEffect(() => {
    if (isPrivate && !shouldRedirectToLogin) {
      if (!member) {
        dispatch(MemberThunks.fetchMeIfNeeded())
      }
    }
  }, [dispatch, isPrivate, member, shouldRedirectToLogin])

  return {
    shouldRedirectDigitalOnlyMember,
    shouldRedirectToUnderage,
    shouldRedirectToLogin,
    shouldRedirectToGraduation,
    shouldRedirectToSSO,
    shouldRedirectToTerms,
    isRelevantPath,
    member,
  }
}
