import { cloneDeep } from 'lodash'
import * as APM from 'util/apm'
import FetchType from '../../util/fetch-types'
import { handleRequestFailure, makeRequest, normalizeJsonToApi, stringifyError } from '../../api/helpers'
import * as MemberSelectors from '../selectors/members'
import { determineRequestNeediness, validatePhoneNumber } from './api'
import * as ApiSelectors from '../selectors/api'
import { getFromStorage } from '../../util/storage'
import * as SessionKeys from '../../util/session-constants'
import { getMemberLastFetch } from '../selectors/members'
import { setFetching } from '../slices/api'
import {
  putAcknowledgments,
  putDependents,
  putLoggedInMember,
  putLoggedInMemberGroup,
  putMember,
  putMemberLastFetch,
  putMemberNames,
  putMemberProviders,
  putDuplicateMembers,
  putDuplicateMemberAttributes,
  clearDuplicateMember,
  clearDuplicateMembers,
} from '../slices/members'
import { clearPaymentMethod, putPaymentMethods } from '../slices/payments'
import { setPaymentsHistory } from '../slices/payments-history'
import { clearPendingVisits, clearVisits } from '../slices/visits'
import { putMedicalHistory } from '../slices/medical-history'
import { putFamilyHistory } from '../slices/family-history'
import { putResources } from '../slices/resources'
import { putAllergies } from '../slices/allergies'
import { putServices } from '../slices/services'
import { putImages } from '../slices/images'
import { fetchGroup, fetchMemberNeeds, onboardClient } from './clients'
import { fetchPendingVisitsIfNeeded, fetchVisitsIfNeeded } from './visits'
import { fetchPossibleConditions } from './medical-history'
import { getPreferredAddress, stripId } from '../../util/member'
import { putTopic } from '../slices/pusher'
import { PusherTopics } from '../../util/pusher-topics'
import { phoneFormat } from '../../util/formatters'
import type { Dispatch } from 'redux'
import Member, { Acknowledgments, Dependent, MemberProvider, Phone, Service } from '../../types/member/member'
import MedicalHistory, { Allergy } from '../../types/member/medicalHistory'
import { Client } from '../../types/client/client'
import { Resource } from '../../types/resource'
import { PayloadUpdateMember } from '../../types/account/form'
import FamilyHistory from '../../types/member/familyHistory'
import { Group } from 'types/client/group'
import { fetchCancellationReasons } from './cancellation-reasons'
import { AccountMergeAuthFlow, DuplicateMember } from 'types/account-merge/memberDuplicate'
import { AccountMergeChallenge, ChallengeName, ChallengeResponse } from 'types/account-merge/AccountMergeChallenge'
import { putAccountMergeToken } from 'redux/slices/auth'
import { GetState } from 'redux/store'
import { toast } from 'util/toast'
import { getGroup } from 'redux/selectors/services'
import { getCampaign } from '../selectors/api'
import { pendoTrack, TrackEventProperties } from 'util/analytics'

export const fetchMemberServices =
  (memberId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchType.getServices, isFetching: true }))
    const success = false
    const handleSuccess = (data: Service) => {
      dispatch(putServices({ services: data }))
    }
    await makeRequest(FetchType.getServices, { memberId }, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.getServices, isFetching: false }))
    return { success }
  }

export const addServiceCode =
  (memberId: string, accessCode: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    dispatch(setFetching({ fetchType: FetchType.addServiceCode, isFetching: true }))
    let success = false
    const handleSuccess = () => {
      success = true
      // @ts-ignore
      dispatch(fetchMemberServices(memberId))
    }
    await makeRequest(FetchType.addServiceCode, { memberId, accessCode }, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.addServiceCode, isFetching: false }))
    return { success }
  }

type DependentDataProps = {
  [key: string]: { allergies: [] | Allergy[]; familyHistory: [] | FamilyHistory; medicalHistory: MedicalHistory }
}

export const prepareDashboard = (memberId: string, dependentData: DependentDataProps) => async (dispatch: Dispatch) => {
  // @ts-ignore
  dispatch(fetchMemberServices(memberId))
  dispatch(clearVisits())
  dispatch(clearPendingVisits())
  // @ts-ignore
  dispatch(fetchCancellationReasons())
  // @ts-ignore
  dispatch(fetchVisitsIfNeeded({ memberId }))
  // @ts-ignore
  dispatch(fetchPendingVisitsIfNeeded({ memberId }))
  // @ts-ignore
  dispatch(fetchPossibleConditions())

  const dependentIds = Object.keys(dependentData) as Array<keyof typeof dependentData>
  if (dependentData && dependentIds.length) {
    dependentIds.forEach((dependentId) => {
      const depData = dependentData[dependentId]
      const { medicalHistory, familyHistory, allergies } = depData
      if (medicalHistory) dispatch(putMedicalHistory({ history: medicalHistory }))
      if (familyHistory) dispatch(putFamilyHistory({ history: familyHistory }))
      if (allergies.length) dispatch(putAllergies({ allergies }))
      // @ts-ignore
      dispatch(fetchVisitsIfNeeded({ memberId: dependentId }))
      // @ts-ignore
      dispatch(fetchPendingVisitsIfNeeded({ memberId: dependentId }))
      dispatch(putTopic({ topic: PusherTopics.member(dependentId) }))
    })
  }
}

type extractMemberDataProps = {
  acknowledgments: Acknowledgments
  memberId: string
  allergies: Allergy
  client: Client
  dependentData: DependentDataProps
  dependents: Dependent[]
  familyHistory: FamilyHistory
  medicalHistory: MedicalHistory
  member: Member
  resources: Resource[]
  images: [] | any[] //TODO Create images type
  memberProviders?: [] | MemberProvider[]
  group: Group
}

export const extractMemberData = (data: extractMemberDataProps) => async (dispatch: Dispatch, getState: GetState) => {
  sessionStorage.setItem(SessionKeys.MEMBER_ID, data.memberId)
  dispatch(putLoggedInMember({ memberId: data.memberId }))
  APM.setUser({ id: data.memberId })
  dispatch(putResources({ resources: data.resources }))
  dispatch(putImages({ images: data.images }))
  dispatch(putMemberProviders({ memberId: data.memberId, providers: data.memberProviders }))
  // @ts-ignore
  await dispatch(fetchGroup(data.member?.groupId))
  const state = getState()

  const memberGroup = getGroup(data.member?.groupId)(state)
  const campaignObj: TrackEventProperties = {
    memberId: data.memberId,
    groupName: memberGroup?.name,
  }

  if (data.medicalHistory) {
    dispatch(putMedicalHistory({ history: data.medicalHistory }))
  }
  if (data.familyHistory) {
    dispatch(putFamilyHistory({ history: data.familyHistory }))
  }
  if (data.group) {
    campaignObj.groupType = data.group?.groupType
    dispatch(putLoggedInMemberGroup(data.group))
  }
  if (data.acknowledgments) {
    dispatch(putAcknowledgments(data.acknowledgments))
  }

  if (data.member?.externalId) {
    // @ts-ignore
    dispatch(fetchMemberDuplicates(data.memberId))
  }
  if (data.client) {
    campaignObj.clientId = data.client?.clientId
    campaignObj.clientName = data.client?.name
    // @ts-ignore
    await dispatch(onboardClient(data.client))
  }
  dispatch(putDependents({ dependents: data.dependents }))
  dispatch(putAllergies({ allergies: data.allergies }))
  await dispatch(putMember({ member: data.member }))

  const campaignId = getCampaign(state)

  if (campaignId) {
    campaignObj.campaignId = campaignId
    pendoTrack('campaignTag', campaignObj)
  }
}

export const fetchMe =
  () =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    let success = false
    dispatch(setFetching({ fetchType: FetchType.getMe, isFetching: true }))
    const params = {}
    const handleSuccess = async (data: extractMemberDataProps) => {
      // @ts-ignore
      await dispatch(extractMemberData(data))
      // @ts-ignore
      await dispatch(prepareDashboard(data.memberId, data.dependentData))
      // @ts-ignore
      await dispatch(fetchMemberNeeds())
      success = true
    }
    await makeRequest(FetchType.getMe, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.getMe, isFetching: false }))
    return { success }
  }

export const fetchMeIfNeeded = () => async (dispatch: Dispatch, getState: () => any) => {
  const memberId = getFromStorage(SessionKeys.MEMBER_ID)
  if (memberId) {
    const isAlreadyFetching = ApiSelectors.isTypeFetching(FetchType.getMe)(getState())
    if (!isAlreadyFetching) {
      await dispatch(
        // @ts-ignore
        determineRequestNeediness({
          cacheTtlInMinutes: 2,
          lastFetchSelector: getMemberLastFetch(memberId),
          fetchNext: fetchMe(),
        }),
      )
    }
  }
}

export const addMember =
  (member: Member) =>
  async (dispatch: Dispatch): Promise<boolean | string> => {
    dispatch(setFetching({ fetchType: FetchType.addMember, isFetching: true }))
    const params = {
      lookup: {
        groupId: member.groupId,
        firstName: member.firstName,
        lastName: member.lastName,
        dob: member.dob,
        email: member.email,
      },
    }
    let success: boolean | string = false
    const handleSuccess = (data: Member) => {
      success = data.memberId
      dispatch(putMember({ member: data }))
    }
    await makeRequest(FetchType.addMember, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.addMember, isFetching: false }))
    return success
  }

export const updateMember =
  (memberId: string, member: Member) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: Member | null }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.updateMember, isFetching: true }))
    const params = { memberId, member }
    const handleSuccess = (data: Member) => {
      dispatch(putMember({ member: data }))
      success = true
      result = data
    }
    await makeRequest(FetchType.updateMember, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.updateMember, isFetching: false }))
    return { success, data: result }
  }

export const updateMemberAcknowledgment =
  (acknowledgmentKey: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: Acknowledgments | null }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.updateMemberAcknowledgment, isFetching: true }))
    const params = { acknowledgmentKey }
    const handleSuccess = (data: Acknowledgments) => {
      dispatch(putAcknowledgments(data))
      success = true
      result = data
    }
    await makeRequest(FetchType.updateMemberAcknowledgment, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.updateMemberAcknowledgment, isFetching: false }))
    return { success, data: result }
  }

export const updateMemberContact =
  (memberId: string, data: PayloadUpdateMember) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: Member | null }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.updateMemberContact, isFetching: true }))
    const contact = cloneDeep<PayloadUpdateMember>(data)
    contact.phones = addToPhoneArray(contact.phones, data.phone)
    // @ts-ignore
    delete contact.phone
    const params = { memberId, contact }
    const handleSuccess = (data: Member) => {
      dispatch(putMember({ member: data }))
      success = true
      result = data
    }
    await makeRequest(FetchType.updateMemberContact, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.updateMemberContact, isFetching: false }))
    return { success, data: result }
  }

export const fetchMember =
  (memberId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: Member | null }> => {
    const success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.getMember, isFetching: true }))
    const params = { memberId }
    const handleSuccess = async (data: Member) => {
      dispatch(putMemberLastFetch({ memberId }))
      await dispatch(putMember({ member: data }))
      result = data
    }
    await makeRequest(FetchType.getMember, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.getMember, isFetching: false }))
    return { success, data: result }
  }

export const fetchMemberIfNeeded = (memberId: string) => async (dispatch: Dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.maybeGetMember, isFetching: true }))
  dispatch(
    // @ts-ignore
    determineRequestNeediness({
      cacheTtlInMinutes: 5,
      fetchNext: fetchMember(memberId),
      lastFetchSelector: MemberSelectors.getMemberLastFetch(memberId),
      existingSelector: MemberSelectors.getMember(memberId),
    }),
  )
  dispatch(setFetching({ fetchType: FetchType.maybeGetMember, isFetching: false }))
}

export const addTransaction =
  (memberId: string, transaction: { id: string; isOverLimit: boolean }, serviceId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: string | null }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.addPaymentTransactionV2, isFetching: true }))
    const params = {
      memberId,
      transaction: {
        serviceId,
        currency: 'USD',
        source: transaction.id,
        isOverLimit: transaction.isOverLimit,
      },
    }
    const handleSuccess = (data: { transactionId: string }) => {
      result = data.transactionId
      success = true
    }
    await makeRequest(FetchType.addPaymentTransactionV2, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.addPaymentTransactionV2, isFetching: false }))
    return { success, data: result }
  }

export const addPaymentMethod =
  (memberId: string, source: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: null | any }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.addPaymentMethod, isFetching: true }))
    const params = { memberId, source }
    const handleSuccess = (data: any) => {
      if (Array.isArray(data)) {
        dispatch(putPaymentMethods({ memberId, methods: data }))
      } else {
        console.error('uh oh, it was just one data object')
        dispatch(putPaymentMethods({ memberId, methods: [data] }))
      }
      result = data
      success = true
    }
    await makeRequest(FetchType.addPaymentMethod, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.addPaymentMethod, isFetching: false }))
    return { success, data: result }
  }

export const addPaymentClient =
  (memberId: string) =>
  async (dispatch: Dispatch, getState: () => any): Promise<boolean> => {
    let success = false
    dispatch(setFetching({ fetchType: FetchType.addPaymentClient, isFetching: true }))

    const member = MemberSelectors.getMember(memberId)(getState())

    const params = {
      memberId,
      paymentClient: {
        memberId,
        email: member.email,
        firstName: member.firstName,
        lastName: member.lastName,
      },
    }
    const handleSuccess = () => {
      success = true
    }
    await makeRequest(FetchType.addPaymentClient, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.addPaymentClient, isFetching: false }))
    return success
  }

export const removePaymentMethod =
  (memberId: string, methodId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    let success = false
    dispatch(setFetching({ fetchType: FetchType.removePaymentMethod, isFetching: true }))

    const params = { memberId, methodId }
    const handleSuccess = () => {
      dispatch(clearPaymentMethod({ memberId, methodId }))
      success = true
    }
    await makeRequest(FetchType.removePaymentMethod, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.removePaymentMethod, isFetching: false }))
    return { success }
  }

export const fetchPaymentMethods =
  (memberId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: null | any }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.getPaymentMethods, isFetching: true }))
    const params = { memberId }
    const handleSuccess = (data: any) => {
      if (Array.isArray(data)) {
        dispatch(putPaymentMethods({ memberId, methods: data }))
      }
      success = true
      result = data
    }
    await makeRequest(FetchType.getPaymentMethods, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.getPaymentMethods, isFetching: false }))
    return { success, data: result }
  }

export const fetchPaymentsHistory =
  (memberId: string) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: null | any }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.getPaymentTransactions, isFetching: true }))
    const params = { memberId }
    const handleSuccess = (data: any) => {
      if (Array.isArray(data)) {
        dispatch(setPaymentsHistory({ memberId, history: data }))
      }
      success = true
      result = data
    }
    await makeRequest(FetchType.getPaymentTransactions, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.getPaymentTransactions, isFetching: false }))
    return { success, data: result }
  }

export const fetchMemberNames =
  (memberIds: string[]) =>
  async (dispatch: Dispatch): Promise<{ success: boolean; data: null | string[] }> => {
    let success = false
    let result = null
    dispatch(setFetching({ fetchType: FetchType.getMemberNames, isFetching: true }))
    const params = { memberIds }
    const handleSuccess = (data: string[]) => {
      dispatch(putMemberNames(data))
      success = true
      result = data
    }
    await makeRequest(FetchType.getMemberNames, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.getMemberNames, isFetching: false }))
    return { success, data: result }
  }

export const fetchMemberNamesIfNeeded = (memberIds: string[]) => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(setFetching({ fetchType: FetchType.maybeGetMemberNames, isFetching: true }))
  const state = getState()
  const isFetching = ApiSelectors.isTypeFetching(FetchType.getMemberNames)(state)
  if (!isFetching) {
    const hasName = (memberId: string) => !!MemberSelectors.getMemberName(memberId)(state)
    const unknownMembers = memberIds.filter((id) => !hasName(id))
    // @ts-ignore
    if (unknownMembers.length > 0) dispatch(fetchMemberNames(unknownMembers))
  }
  dispatch(setFetching({ fetchType: FetchType.maybeGetMemberNames, isFetching: false }))
}

export const addToPhoneArray = (phones: Phone[] = [], phoneToAdd: string | Phone) => {
  // set all existing phones to non-preferred
  const phone = stripId(phoneToAdd)
  phones = phones.map((p) => ({ ...p, preferred: false }))
  const existing = phones.find((p) => p.number === phone.number)
  if (existing) {
    // update element to new deets
    existing.preferred = true
    existing.type = phone.type
    existing.number = phone.number
    if (phone.extension) {
      existing.extension = phone.extension
    } else {
      delete existing.extension
    }
    existing.acceptSms = phone.acceptSms
  } else {
    // add a brand new phone
    phones.push({ ...phone, preferred: true })
  }
  return phones
}

export const addPhoneNumber =
  (memberId: string, phone: Phone) =>
  async (
    dispatch: Dispatch,
    getState: () => any,
  ): Promise<{ success: boolean; data: null | Member; error?: string }> => {
    dispatch(
      setFetching({
        fetchType: FetchType.updateMemberContact,
        isFetching: true,
      }),
    )
    // @ts-ignore
    const phoneValidation = await dispatch(validatePhoneNumber(phone.number))
    let success = false
    let result = null
    let error = ''
    if (phoneValidation.success) {
      const state = getState()
      const member = MemberSelectors.getMember(memberId)(state)
      // No member, no chance at success
      if (!member) return { success, data: result }

      // Merge in new phone number (avoid duplicates)
      const phones = addToPhoneArray(cloneDeep(member.phones) || [], phone)

      // clean up addresses for api, find preferred if possible
      const safeAddress = cloneDeep(getPreferredAddress(member))
      delete safeAddress.addressType
      delete safeAddress.preferred

      const params = {
        memberId,
        contact: {
          phones,
          billingAddress: safeAddress,
          mailingAddress: safeAddress,
        },
      }

      const handleSuccess = async (data: Member) => {
        dispatch(putMember({ member: data }))
        success = true
        result = data
      }

      const handlerError = (_error) => {
        error = stringifyError(_error)
      }

      await makeRequest(FetchType.updateMemberContact, params, handleSuccess, handlerError)
    } else {
      error = `${phoneFormat(phone?.number)} is an invalid phone number`
    }

    dispatch(
      setFetching({
        fetchType: FetchType.updateMemberContact,
        isFetching: false,
      }),
    )

    return { success, data: result, error }
  }

export const addMemberFeedback =
  (memberId: string, data: { feedback: string }) =>
  async (dispatch: Dispatch): Promise<{ success: boolean }> => {
    let success = false
    dispatch(setFetching({ fetchType: FetchType.addMemberFeedback, isFetching: true }))

    const { feedback } = data

    const params = { memberId, feedback }
    const handleSuccess = async () => {
      success = true
    }
    await makeRequest(FetchType.addMemberFeedback, params, handleSuccess, handleRequestFailure(dispatch))
    dispatch(setFetching({ fetchType: FetchType.addMemberFeedback, isFetching: false }))
    return { success }
  }

export const updateAnnualTermsConditions = (memberId: string, version: string) => async (dispatch: Dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.updateTermsConditions, isFetching: true }))
  let success = false
  let result = null
  const params = { memberId, type: 'tc-app', version }
  const handleSuccess = (data: Member) => {
    success = true
    result = data
    dispatch(putMember({ member: data }))
  }
  await makeRequest(FetchType.updateTermsConditions, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.updateTermsConditions, isFetching: false }))
  return { success, data: result }
}

export const fetchMemberDuplicates = (memberId: string, filter?: { taskId?: string }) => async (dispatch: Dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getMemberDuplicates, isFetching: true }))
  let success = false
  let result = null
  const params = { params: filter ? { filter } : undefined, memberId }

  const handleSuccess = (data: DuplicateMember[]) => {
    success = true
    result = data
    dispatch(putDuplicateMembers({ memberId, duplicateMembers: data }))
  }

  await makeRequest(FetchType.getMemberDuplicates, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getMemberDuplicates, isFetching: false }))
  return { success, data: result }
}

export const postAccountMergeInitialize =
  (duplicateMemberId: string, authFlow: AccountMergeAuthFlow) => async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setFetching({ fetchType: FetchType.postAccountMergeInit, isFetching: true }))
    let success = false
    let result = null
    const params = { authFlow, authParameters: { duplicateMemberId } }
    const loggedInMemberId = getState().member.meta.loggedInMemberId

    const handleSuccess = (data: AccountMergeChallenge) => {
      success = true
      result = data
      if (data?.session) {
        dispatch(putAccountMergeToken({ token: data.session }))
      }

      if (data?.challengeName === 'mfa_select_type') {
        dispatch(
          putDuplicateMemberAttributes({
            memberId: loggedInMemberId,
            duplicateMemberId,
            memberAttributes: data.challengeParameters,
          }),
        )
      }
    }

    const handleFailure = (err: string) => {
      toast.error(err)
    }

    await makeRequest(FetchType.postAccountMergeInit, params, handleSuccess, handleFailure)
    dispatch(setFetching({ fetchType: FetchType.postAccountMergeInit, isFetching: false }))
    return { success, data: result }
  }

export const postAccountMergeChallenge =
  (challengeName: ChallengeName, challengeResponses: Record<string, string>) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setFetching({ fetchType: FetchType.postAccountMergeChallenge, isFetching: true }))
    let success = false
    let result = null
    let closeFlow = undefined
    const session = getState().auth.sessionTokens.accountMergeToken
    const memberId = getState().member.meta.loggedInMemberId
    const params = { challengeName, challengeResponses, session }

    const handleSuccess = (data: ChallengeResponse) => {
      success = true
      result = data
      if (data.authenticationResult.success) {
        dispatch(clearDuplicateMember({ memberId, duplicateMemberId: challengeResponses.duplicateMemberId }))
        const remainingDuplicates = Object.values(getState().member.duplicates[memberId] || {})?.length
        if (remainingDuplicates) {
          closeFlow = false
        } else {
          closeFlow = true
        }
      }
    }

    const handleFailure = (err: string) => {
      toast.error(err)
    }
    await makeRequest(FetchType.postAccountMergeChallenge, params, handleSuccess, handleFailure)

    dispatch(setFetching({ fetchType: FetchType.postAccountMergeChallenge, isFetching: false }))
    return { success, data: result, closeFlow }
  }

export const postAccountMergeSaveForLater = () => async (dispatch: Dispatch, getState: GetState) => {
  dispatch(setFetching({ fetchType: FetchType.postAccountMergeSaveForLater, isFetching: true }))
  let success = false
  let result = undefined
  const memberId = getState().member.meta.loggedInMemberId
  const params = { memberId }
  const handleSuccess = (data: ChallengeResponse) => {
    success = true
    result = data
    dispatch(clearDuplicateMembers())
  }

  const handleFailure = (err: string) => {
    toast.error(err)
  }

  await makeRequest(FetchType.postAccountMergeSaveForLater, params, handleSuccess, handleFailure)
  dispatch(setFetching({ fetchType: FetchType.postAccountMergeSaveForLater, isFetching: false }))
  return { success, data: result }
}
