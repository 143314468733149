import * as Yup from 'yup'
import catchApiFailure from 'api/requests/catch-api-error'
import ApiFactory, { ApiTypes } from 'api'
import configBuilder from 'api/requests/config-builder'
import { MedicationSchemaV2 } from 'schemas/MedicalHistorySchema'
import { normalizeJsonToApi } from 'api/helpers'
import { formatMedicationDateToApi } from 'util/medication'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  memberId: Yup.string().required(),
  medication: MedicationSchemaV2.required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const make = async (params) => {
  const { direction, strengthAndUnit, startDate, endDate, dispensableDrugId, ...medication } = MedicationSchemaV2.cast(
    params.medication,
    { stripUnknown: true },
  )

  return normalizeJsonToApi({
    medications: [
      {
        ...medication,
        direction: direction || null,
        strengthAndUnit: strengthAndUnit || null,
        startDate: formatMedicationDateToApi(startDate) || null,
        endDate: formatMedicationDateToApi(endDate) || null,
        dispensableDrugId: dispensableDrugId || null,
      },
    ],
  })
}
export const url = ({ memberId }) => `/v2/${memberId}/personal/history/medications`
export const execute = async (params) =>
  api.post(url(params), await make(params), configBuilder(params)).catch(catchApiFailure)
