import moment from 'moment-timezone'
import { keys, isEmpty } from 'lodash'

export const SCHEDULING_DATE_FORMAT = 'ddd MM/D/YYYY'
export const SCHEDULING_TIME_FORMAT = 'h:mm A'
export const SCHEDULING_DAY_FORMAT = 'D'

export const reduceAvailabilityToTimesByDate = (availability, timezone) => {
  return availability.reduce((acc, datetime) => {
    const dt = moment(datetime).tz(timezone)
    const date = dt.format(SCHEDULING_DATE_FORMAT)
    if (!acc[date]) acc[date] = []
    acc[date].push(dt.format(SCHEDULING_TIME_FORMAT))
    return acc
  }, {})
}

export const reduceAvailabilityToTimesByDayByMonth = (availability, timezone) => {
  const monthMap = availability.reduce((acc, datetime) => {
    const dt = moment(datetime).tz(timezone)
    const month = dt.format('MMMM YYYY')
    if (!acc[month]) acc[month] = []
    acc[month].push(datetime)
    return acc
  }, {})
  // eslint-disable-next-line array-callback-return
  keys(monthMap).map((month) => {
    const val = monthMap[month]
    const value = val.reduce((acc, datetime) => {
      const dt = moment(datetime).tz(timezone)
      const day = dt.format(SCHEDULING_DAY_FORMAT)
      if (!acc[day]) acc[day] = []
      acc[day].push(dt.format(SCHEDULING_TIME_FORMAT))
      return acc
    }, {})
    monthMap[month] = value
  })
  return monthMap
}

export const extractDate = (value, timezone, timesByDate) => {
  if (!value) {
    if (isEmpty(timesByDate)) return null
    const dates = Object.keys(timesByDate).map((key) =>
      moment(key, SCHEDULING_DATE_FORMAT).tz(timezone).format('YYYY-MM-DD'),
    )
    dates.sort()
    const [first] = dates
    if (isEmpty(first)) return null
    return moment(first, 'YYYY-MM-DD').tz(timezone).format(SCHEDULING_DATE_FORMAT)
  }
  const date = moment(value).tz(timezone)
  return date.format(SCHEDULING_DATE_FORMAT)
}

export const extractTime = (value, timezone) => {
  if (!value) return ''
  const date = moment(value).tz(timezone)
  return date.format(SCHEDULING_TIME_FORMAT)
}

export const extractTimes = (timesByDate = {}, date) => {
  if (!timesByDate || !timesByDate[date]) return []
  return timesByDate[date]
}

export const printVisitDates = (times, timezone) => {
  return times.map((time) => {
    const mmt = moment(time).tz(timezone)
    return {
      day: mmt.format('dddd YYYY'),
      date: mmt.format('ddd, MMM D'),
      time: mmt.format('h:mm A'),
    }
  })
}
