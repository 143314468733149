import moment from 'moment'

export const frequencies = [
  'Once a day',
  'Twice a day',
  'Three times a day',
  'Four times a day',
  'As needed',
  'Once every other day',
  'Once every 3 days',
  'Once a week',
  'Once every other week',
  'Once a month',
  'Other',
]

export const units = [
  'Tablet/Pill',
  'Tablet - Delayed Release',
  'Tablet - Extended Release ',
  'Suspension',
  'Capsule',
  'Powder',
  'Liquid',
  'Lotion',
  'Cream',
  'Solution',
  'Ointment',
  'Gel',
  'Foam',
  'Inhaler',
  'Spray',
  'Swab',
  'Soap',
]

export const unitStrengths = ['mg', 'ml', 'mcg', '%', 'mg/mL', 'mg/3mL', 'm/5mL', 'mcg/inhalation']

export const parseMedicationDate = (date?: string) => (date ? moment(new Date(date)).format('MM-DD-YYYY') : null)

export const getMedicationDateTimestamp = (date?: string) => (date ? moment(date, 'MM-DD-YYYY').valueOf() : null)

export const formatMedicationDateToApi = (date?: string) =>
  date ? moment(getMedicationDateTimestamp(date)).format('YYYY-MM-DD') : null
