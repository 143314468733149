import { clearNeed, putClient, putGroup, putNeeds, putRestrictions, putSurvey, putSurveys } from '../slices/services'
import * as SessionKeys from '../../util/session-constants'
import { putProgramLogoUrl, putProgramName } from '../slices/view'
import { PROGRAM_NAME } from '../../util/session-constants'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { setFetching } from '../slices/api'
import FetchType from '../../util/fetch-types'
import { fetchMember } from './members'
import { getLoggedInMemberId, getMember } from '../selectors/members'
import Config from '../../config'

const getProgramLogo = ({ clientId }) => {
  return `${Config.s3BaseUrl}/client-logos/${clientId}.png`
}

export const onboardClientResources = (data) => async (dispatch) => {
  if (!data) return
  try {
    if (data.program) {
      localStorage.setItem(SessionKeys.PROGRAM_NAME, data.program)
      await dispatch(putProgramName({ programName: data.program }))
    } else {
      const pName = localStorage.getItem(SessionKeys.PROGRAM_NAME)
      if (pName) {
        localStorage.removeItem(SessionKeys.PROGRAM_NAME)
        await dispatch(putProgramName({ programName: PROGRAM_NAME }))
      }
    }

    if (data.hasLogo) {
      localStorage.setItem(SessionKeys.PROGRAM_LOGO_URL, data.clientId)
      const logoUrl = getProgramLogo(data)
      await dispatch(putProgramLogoUrl({ logoUrl }))
    } else {
      const lUrl = localStorage.getItem(SessionKeys.PROGRAM_LOGO_URL)
      if (lUrl) {
        // remove from storage
        localStorage.removeItem(SessionKeys.PROGRAM_LOGO_URL)
        await dispatch(putProgramLogoUrl({ logoUrl: null }))
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export const onboardClient = (client) => async (dispatch) => {
  if (!client) return
  dispatch(putClient({ client }))
  dispatch(onboardClientResources(client))
}

export const loadExistingProgram = async (dispatch) => {
  let result = {
    programName: PROGRAM_NAME,
    logoUrl: null,
  }
  try {
    const programName = localStorage.getItem(SessionKeys.PROGRAM_NAME)
    if (programName) {
      result.programName = programName
      await dispatch(putProgramName({ programName }))
      document.title = programName
    }
    const clientId = localStorage.getItem(SessionKeys.PROGRAM_LOGO_URL)
    if (clientId) {
      const logoUrl = getProgramLogo({ clientId })
      result.logoUrl = logoUrl
      await dispatch(putProgramLogoUrl({ logoUrl }))
    }
  } catch (e) {
    console.error(e)
  }
  return result
}

export const fetchClientByDomain = (domain) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getClientByDomain, isFetching: true }))
  let success = false
  let result = null
  const params = { domain }
  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(onboardClientResources(data))
  }
  const handleError = (err) => {
    console.error(err)
  }

  await makeRequest(FetchType.getClientByDomain, params, handleSuccess, handleError)

  dispatch(setFetching({ fetchType: FetchType.getClientByDomain, isFetching: false }))
  return { success, data: result }
}

export const fetchSurveys = (clientId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getSurveys, isFetching: true }))
  let success = false
  let result = null
  const params = { clientId }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(putSurveys({ surveys: data }))
  }

  await makeRequest(FetchType.getSurveys, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getSurveys, isFetching: false }))
  return { success, data: result }
}

export const fetchSurvey = (surveyId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getSurveyQuestions, isFetching: true }))
  let success = false
  let result = null
  const params = { surveyId }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(putSurvey({ survey: data }))
  }

  await makeRequest(FetchType.getSurveyQuestions, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getSurveyQuestions, isFetching: false }))
  return { success, data: result }
}

export const completeSurvey =
  ({ surveyId, answers }) =>
  async (dispatch, getState) => {
    dispatch(setFetching({ fetchType: FetchType.completeSurvey, isFetching: true }))
    let success = false
    let result = null

    const memberId = getLoggedInMemberId(getState())

    const params = { surveyId, answers, memberId }

    const handleSuccess = async (data) => {
      success = true
      result = data
      await dispatch(fetchMember(getLoggedInMemberId(getState())))
    }

    await makeRequest(FetchType.completeSurvey, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(setFetching({ fetchType: FetchType.completeSurvey, isFetching: false }))
    return { success, data: result }
  }

export const fetchMemberNeeds = () => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getNeeds, isFetching: true }))
  let success = false
  let result = null

  const memberId = getLoggedInMemberId(getState())

  const params = { memberId }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(putNeeds({ needs: data }))
  }

  await makeRequest(FetchType.getNeeds, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getNeeds, isFetching: false }))
  return { success, data: result }
}

export const fetchNeed = (needId) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getNeed, isFetching: true }))
  let success = false
  let result = null

  const memberId = getLoggedInMemberId(getState())

  const params = { memberId, needId }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(putNeeds({ needs: [data] }))
  }

  await makeRequest(FetchType.getNeed, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getNeed, isFetching: false }))
  return { success, data: result }
}

export const addMemberNeed = (need) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.addNeed, isFetching: true }))
  let success = false
  let result = null

  const memberId = getLoggedInMemberId(getState())

  const params = { memberId, need }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(putNeeds({ needs: [data] }))
  }

  await makeRequest(FetchType.addNeed, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.addNeed, isFetching: false }))
  return { success, data: result }
}

export const removeMemberNeed = (needId) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.removeNeed, isFetching: true }))
  let success = false
  let result = null

  const memberId = getLoggedInMemberId(getState())

  const params = { memberId, needId }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(clearNeed({ needId }))
  }

  await makeRequest(FetchType.removeNeed, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.removeNeed, isFetching: false }))
  return { success, data: result }
}

export const fetchGroup = (groupId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getGroup, isFetching: true }))
  let success = false
  let result = null

  const params = { groupId }

  const handleSuccess = async (data) => {
    success = true
    result = data
    const group = {
      ...data.group,
      client_id: data.client_id,
      client: data.client,
    }
    await dispatch(putGroup({ group }))
  }

  await makeRequest(FetchType.getGroup, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getGroup, isFetching: false }))
  return { success, data: result }
}

export const fetchVisitRestrictions = () => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getVisitRestrictions, isFetching: true }))
  let success = false
  let result = null

  const state = getState()
  const memberId = getLoggedInMemberId(state)
  const member = getMember(memberId)(state)

  const params = {
    params: {
      filter: {
        clientId: member?.clientId,
        groupId: member?.groupId,
        enabled: 1,
      },
    },
  }

  const handleSuccess = async (data) => {
    success = true
    result = data
    await dispatch(putRestrictions({ restrictions: data?.paginated }))
  }

  await makeRequest(FetchType.getVisitRestrictions, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getVisitRestrictions, isFetching: false }))
  return { success, data: result }
}

export const fetchClientVisitCount = (clientId, filter) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getClientVisitCount, isFetching: true }))
  let success = false
  let result = null
  const params = { clientId, params: { page: 0, limit: 10, filter: { creditUsed: 1, ...filter } } }
  const handleSuccess = (data) => {
    success = true
    result = data.meta.results
  }
  await makeRequest(FetchType.getClientVisitCount, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getClientVisitCount, isFetching: false }))
  return { success, count: result }
}

export const saveCareQuestionnaire = (visitType, answers, callback) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.saveCareQuestionnaire, isFetching: true }))
  let success = false
  let result = null
  const params = { visitType, answers }
  const handleSuccess = (data) => {
    success = true
    result = data
  }
  await makeRequest(FetchType.saveCareQuestionnaire, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.saveCareQuestionnaire, isFetching: false }))
  if (callback && success) callback()
  return { success, result }
}
