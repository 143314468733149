import { useSelector } from 'react-redux'
import { getLoggedInMember, getLoggedInMemberId, getMemberAge } from 'redux/selectors/members'
import { getServices } from 'redux/selectors/services'
import { LDContext } from 'launchdarkly-react-client-sdk'

type UserOrigin = 'web' | 'mobile'

type LDContextWithCustomAttributes = LDContext & {
  user_type?: string
  client_id?: string
  group_id?: string
  services?: string[]
  sex?: string
  age?: number
  origin: UserOrigin
}

type FlaggingAttributes = {
  isAttributesReady: boolean
  attributes: LDContextWithCustomAttributes
}

const useFlaggingAttributes = (): FlaggingAttributes => {
  const memberId = useSelector(getLoggedInMemberId)
  const member = useSelector(getLoggedInMember)
  const age = useSelector(getMemberAge(member?.memberId))
  const services = useSelector(getServices)

  const isAttributesReady = !!memberId && !!member

  const attributes: LDContextWithCustomAttributes = {
    key: memberId,
    kind: 'user',
    anonymous: !isAttributesReady,
    user_type: 'member',
    client_id: member?.clientId,
    group_id: member?.groupId,
    services: services?.map((s) => s.serviceType),
    sex: member?.gender,
    age,
    origin: 'web',
    _meta: {
      privateAttributes: ['user_type', 'client_id', 'group_id', 'services', 'sex', 'age', 'origin'],
    },
  }

  return {
    isAttributesReady,
    attributes,
  }
}

export default useFlaggingAttributes
